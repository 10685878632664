exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-ibrahim-tsx": () => import("./../../../src/pages/case-ibrahim.tsx" /* webpackChunkName: "component---src-pages-case-ibrahim-tsx" */),
  "component---src-pages-centers-of-excellence-tsx": () => import("./../../../src/pages/centers-of-excellence.tsx" /* webpackChunkName: "component---src-pages-centers-of-excellence-tsx" */),
  "component---src-pages-health-care-professionals-contact-tsx": () => import("./../../../src/pages/health-care-professionals/contact.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-contact-tsx" */),
  "component---src-pages-health-care-professionals-effects-tsx": () => import("./../../../src/pages/health-care-professionals/effects.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-effects-tsx" */),
  "component---src-pages-health-care-professionals-index-tsx": () => import("./../../../src/pages/health-care-professionals/index.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-index-tsx" */),
  "component---src-pages-health-care-professionals-information-tsx": () => import("./../../../src/pages/health-care-professionals/information.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-information-tsx" */),
  "component---src-pages-health-care-professionals-mode-of-action-tsx": () => import("./../../../src/pages/health-care-professionals/mode-of-action.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-mode-of-action-tsx" */),
  "component---src-pages-health-care-professionals-mri-guidelines-tsx": () => import("./../../../src/pages/health-care-professionals/mri-guidelines.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-mri-guidelines-tsx" */),
  "component---src-pages-health-care-professionals-studies-easee-4-you-tsx": () => import("./../../../src/pages/health-care-professionals/studies/easee4you.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-studies-easee-4-you-tsx" */),
  "component---src-pages-health-care-professionals-studies-easee-lee-tsx": () => import("./../../../src/pages/health-care-professionals/studies/easee-lee.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-studies-easee-lee-tsx" */),
  "component---src-pages-health-care-professionals-studies-easee-pearl-tsx": () => import("./../../../src/pages/health-care-professionals/studies/easee-pearl.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-studies-easee-pearl-tsx" */),
  "component---src-pages-health-care-professionals-studies-index-tsx": () => import("./../../../src/pages/health-care-professionals/studies/index.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-studies-index-tsx" */),
  "component---src-pages-health-care-professionals-technology-tsx": () => import("./../../../src/pages/health-care-professionals/technology.tsx" /* webpackChunkName: "component---src-pages-health-care-professionals-technology-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-living-lab-2024-tsx": () => import("./../../../src/pages/living-lab-2024.tsx" /* webpackChunkName: "component---src-pages-living-lab-2024-tsx" */),
  "component---src-pages-living-lab-information-tsx": () => import("./../../../src/pages/living-lab/information.tsx" /* webpackChunkName: "component---src-pages-living-lab-information-tsx" */),
  "component---src-pages-living-lab-register-tsx": () => import("./../../../src/pages/living-lab/register.tsx" /* webpackChunkName: "component---src-pages-living-lab-register-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-newsletter-signup-success-tsx": () => import("./../../../src/pages/newsletter-signup-success.tsx" /* webpackChunkName: "component---src-pages-newsletter-signup-success-tsx" */),
  "component---src-pages-patients-and-caregivers-how-easee-works-tsx": () => import("./../../../src/pages/patients-and-caregivers/how-easee-works.tsx" /* webpackChunkName: "component---src-pages-patients-and-caregivers-how-easee-works-tsx" */),
  "component---src-pages-patients-and-caregivers-index-tsx": () => import("./../../../src/pages/patients-and-caregivers/index.tsx" /* webpackChunkName: "component---src-pages-patients-and-caregivers-index-tsx" */),
  "component---src-pages-patients-and-caregivers-information-tsx": () => import("./../../../src/pages/patients-and-caregivers/information.tsx" /* webpackChunkName: "component---src-pages-patients-and-caregivers-information-tsx" */)
}

